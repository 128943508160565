import React from "react"
import { Fab } from "@material-ui/core"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"

const BackToTop: React.FC = () => {
  const handleClick = () => {
    const anchor = document.querySelector("#back-to-top-anchor")

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }

  return (
    <Fab color="secondary" onClick={handleClick}>
      <KeyboardArrowUpIcon />
    </Fab>
  )
}

export default BackToTop
