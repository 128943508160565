import React from "react"
import { Link } from "gatsby"
import {
  Breadcrumbs as MaterialBreadcrumbs,
  Box,
  Container,
  Typography,
} from "@material-ui/core"
import { theme } from "../styles/theme"

type Props = {
  breadcrumbs: {
    name: string
    url?: string
  }[]
}

const Breadcrumbs: React.FC<Props> = props => {
  return (
    <Box bgcolor={theme.palette.secondary.main} py={1}>
      <Container>
        <MaterialBreadcrumbs>
          {props.breadcrumbs.map(breadcrumb => {
            if (breadcrumb.url) {
              return (
                <Link
                  key={breadcrumb.name}
                  to={breadcrumb.url}
                  style={{
                    color: `${theme.palette.common.white}`,
                    fontSize: "0.875rem",
                  }}
                >
                  {breadcrumb.name}
                </Link>
              )
            } else {
              return (
                <Typography component="p" key={breadcrumb.name} variant="body2">
                  {breadcrumb.name}
                </Typography>
              )
            }
          })}
        </MaterialBreadcrumbs>
      </Container>
    </Box>
  )
}

export default Breadcrumbs
