import { createMuiTheme } from "@material-ui/core"
import { grey, yellow, amber } from "@material-ui/core/colors"

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: yellow[50],
      main: yellow[700],
      contrastText: grey[50],
    },
    secondary: {
      light: amber[50],
      main: amber[900],
      contrastText: grey[50],
    },
    common: {
      white: grey[50],
      black: grey[900],
    },
    text: {
      primary: grey[900],
      secondary: grey[50],
    },
    background: {
      default: grey[100],
    },
  },
})
