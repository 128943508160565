import React from "react"

import {
  Box,
  Container,
  Grid,
  Hidden,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { theme } from "../styles/theme"

import "../styles/reset.css"

import Breadcrumbs from "../components/Breadcrumbs"
import Footer from "../components/Footer"
import Header from "../components/Header"
import ScrollTop from "./BackToTop"
import Sidebar from "../components/Sidebar"

type Props = {
  hero?: boolean
  breadcrumbs?: {
    name: string
    url?: string
  }[]
}

const Layout: React.FC<Props> = props => {
  return (
    <MuiThemeProvider theme={theme}>
      <Header />
      <Toolbar id="back-to-top-anchor" />
      {props.hero && <Hero />}

      {props.breadcrumbs && <Breadcrumbs breadcrumbs={props.breadcrumbs} />}

      <Box
        bgcolor={theme.palette.background.default}
        component="article"
        py={5}
      >
        <Container>
          <Hidden smDown>
            <Grid container spacing={3}>
              <Grid item lg={8}>
                {props.children}
              </Grid>

              <Grid item lg={4}>
                <Sidebar />
              </Grid>
            </Grid>
          </Hidden>

          <Hidden mdUp>{props.children}</Hidden>
        </Container>

        <Hidden smDown>
          <Box pr={5} textAlign="right">
            <ScrollTop />
          </Box>
        </Hidden>
      </Box>

      <Footer />
    </MuiThemeProvider>
  )
}

const Hero: React.FC = () => {
  return (
    <Box
      px={2}
      py={10}
      style={{
        background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      }}
    >
      <Typography
        align="center"
        component="p"
        gutterBottom
        variant="body1"
        style={{ color: `${theme.palette.common.white}` }}
      >
        子ども向けプログラミング・ロボット教室比較サイト
      </Typography>
      <Typography
        align="center"
        component="h1"
        variant="h3"
        style={{ color: `${theme.palette.common.white}` }}
      >
        Kids Tech Labo
      </Typography>
    </Box>
  )
}
export default Layout
