import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  AppBar,
  Box,
  Container,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
} from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import CloseIcon from "@material-ui/icons/Close"
import MenuIcon from "@material-ui/icons/Menu"

import { HeaderQuery } from "../../types/graphql-types"

const Header: React.FC = () => {
  const query: HeaderQuery = useStaticQuery(graphql`
    query Header {
      allMicrocmsCategory {
        nodes {
          name
          slug
        }
      }
    }
  `)

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      <AppBar color="inherit" position="fixed">
        <Container>
          <Toolbar style={{ padding: 0 }}>
            <Hidden smDown>
              <Link to="/">
                <Typography color="primary" component="h2" variant="h5">
                  Kids Tech Labo
                </Typography>
              </Link>
            </Hidden>

            <Hidden mdUp>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Link to="/">
                    <Typography color="primary" component="h2" variant="h5">
                      Kids Tech Labo
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <IconButton color="inherit" onClick={handleDrawerOpen}>
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer anchor="right" open={open} variant="persistent">
        <Box width="100vw">
          <AppBar color="inherit">
            <Container>
              <Toolbar style={{ padding: 0 }}>
                <Grid container alignItems="center" justify="space-between">
                  <Grid item>
                    <Link to="/">
                      <Typography color="primary" component="h2" variant="h5">
                        Kids Tech Labo
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item>
                    <IconButton color="inherit" onClick={handleDrawerClose}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Toolbar>
            </Container>
          </AppBar>

          <Toolbar />

          <Box mt={5}>
            <Container>
              <Typography component="h2" gutterBottom variant="h5">
                カテゴリー
              </Typography>

              <List>
                {query.allMicrocmsCategory.nodes.map(category => {
                  const categoryName = category.name || ""
                  const url = `/category/${category.slug}`

                  return (
                    <Link key={categoryName} to={url}>
                      <ListItem button divider>
                        <ArrowForwardIosIcon color="primary" fontSize="small" />
                        <Typography
                          color="textPrimary"
                          component="h3"
                          variant="h6"
                        >
                          {categoryName}
                        </Typography>
                      </ListItem>
                    </Link>
                  )
                })}
              </List>
            </Container>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default Header
