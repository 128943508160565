import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  Badge,
  Box,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"

import { SidebarQuery } from "../../types/graphql-types"

const Sidebar: React.FC = () => {
  const query: SidebarQuery = useStaticQuery(graphql`
    query Sidebar {
      allMicrocmsPosts(sort: { fields: publishedAt, order: ASC }, limit: 5) {
        edges {
          node {
            category {
              name
              slug
            }
            content {
              product {
                eyecatch {
                  url
                }
                feature
                name
                url
              }
            }
            eyecatch {
              url
            }
            id
            postsId
            publishedAt(formatString: "YYYY/MM/DD")
            slug
            title
          }
        }
      }
      allMicrocmsCategory {
        nodes {
          name
          slug
        }
      }
    }
  `)

  return (
    <>
      <Box>
        <Ranking ranking={query} />
        <Category ranking={query} />
      </Box>
    </>
  )
}

type Props = {
  ranking: SidebarQuery
}

const Ranking: React.FC<Props> = props => {
  return (
    <Box>
      <Typography component="h2" gutterBottom variant="h5">
        人気記事
      </Typography>

      <Paper>
        <Box p={2}>
          <PostList ranking={props.ranking} />
        </Box>
      </Paper>
    </Box>
  )
}

const PostList: React.FC<Props> = props => {
  return (
    <List>
      {props.ranking.allMicrocmsPosts.edges.map(({ node }, index) => {
        const eyecatchUrl = node.eyecatch?.url || ""
        const slug = node.slug || ""
        const title = node.title || ""

        return (
          <Link key={node.id} to={`/posts/${slug}`}>
            <ListItem button divider>
              <Grid container>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Badge
                    color="primary"
                    badgeContent={index + 1}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <img
                      src={eyecatchUrl}
                      width="100px"
                      height="70px"
                      alt={title}
                    />
                  </Badge>
                </Grid>

                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <Typography
                    color="textPrimary"
                    component="h3"
                    variant="body2"
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </Link>
        )
      })}
    </List>
  )
}

const Category: React.FC<Props> = props => {
  return (
    <Box mt={3}>
      <Typography component="h2" gutterBottom variant="h5">
        カテゴリー
      </Typography>

      <Paper>
        <Box p={2}>
          <CategoryList ranking={props.ranking} />
        </Box>
      </Paper>
    </Box>
  )
}

const CategoryList: React.FC<Props> = props => {
  return (
    <List>
      {props.ranking.allMicrocmsCategory.nodes.map(category => {
        const categoryName = category.name || ""
        const url = `/category/${category.slug}`

        return (
          <Link key={categoryName} to={url}>
            <ListItem button divider>
              <ArrowForwardIosIcon color="primary" fontSize="small" />
              <Typography color="textPrimary" component="h3" variant="body1">
                {categoryName}
              </Typography>
            </ListItem>
          </Link>
        )
      })}
    </List>
  )
}

export default Sidebar
