import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

type Props = {
  description?: string
  title?: string
}

const SEO: React.FC<Props> = props => {
  const query = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            lang
            locale
            title
          }
        }
      }
    `
  )

  const title = props.title || query.site.siteMetadata.title
  const metaDescription =
    props.description || query.site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang: query.site.siteMetadata.lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: query.site.siteMetadata.locale,
        },
        {
          property: `og:site_name`,
          content: query.site.siteMetadata.title,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: query.site.siteMetadata.author,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:title`,
          content: title,
        },
      ]}
    />
  )
}

export default SEO
