import React from "react"
import { Link } from "gatsby"
import { Box, Container, Typography } from "@material-ui/core"
import { theme } from "../styles/theme"

const Footer: React.FC = () => {
  return (
    <Box
      bgcolor={theme.palette.secondary.main}
      component="footer"
      py={4}
      textAlign="center"
    >
      <Container>
        <Link to="/">
          <Typography
            color="textSecondary"
            component="h2"
            gutterBottom
            variant="h5"
          >
            Kids Tech Labo
          </Typography>
        </Link>

        <Typography color="textSecondary" component="p" variant="body2">
          Copyright © 2020 Kids Tech Labo
        </Typography>
      </Container>
    </Box>
  )
}

export default Footer
